<template>
  <div class="">
    <h3>View Runner Request</h3>
    <form>
      <div class="form-group">
        <label for="catDescription">ID</label>
        <input
          class="form-control"
          type="text"
          v-model="runnerRequest.runner_category_id"
          id="request_id"
          disabled
        />
      </div>
      <div class="form-group">
        <label for="catDescription">Category</label>
        <input
          class="form-control"
          type="text"
          v-model="runnerRequest.category"
          id="request_category"
          disabled
        />
      </div>
      <div class="form-group">
        <label for="catDescription">Description</label>
        <textarea
          class="form-control"
          id="request_description"
          rows="3"
          v-model="runnerRequest.description"
          disabled
        ></textarea>
      </div>
      <div class="form-group">
        <label for="catDescription">Status</label>
        <input
          class="form-control"
          type="text"
          v-model="runnerRequest.status"
          id="request_status"
          disabled
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "RunnerRequest",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.request_id = this.$route.params.id;
    this.getRunnerDetails();
  },
  data() {
    return {
      request_id: "",
      runnerCategories: [],
      myRequest: {},
      runnerRequest: {
        description: "",
        runner_category_id: "",
        category: "",
        status: "",
        date: "",
      },
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getRunnerDetails() {
      axios
        .get("runners/requests/" + this.request_id)
        .then((response) => {
          // console.log("Get Runner Request : " + JSON.stringify(response.data));
          this.myRequest = response.data;
          this.runnerRequest.runner_category_id = this.request_id;
          this.runnerRequest.description = response.data.body;
          this.runnerRequest.status = response.data.status;
        });
    },
  },
};
</script>
